<div class="flex items-center mb-3">
  <div class="btn-itax flex justify-between items-center w-full">
    <el-button @click="getListMailBox()" size="mini">
      <img src="@/assets/icons/icon_sync.svg" alt="" class="h-4 w-4" />
    </el-button>
    <search-input
      class="ml-3 md:ml-0"
      style="width: 25rem"
      v-model="search"
      :isIcon="true"
      placeholder="Tìm kiếm theo chủ đề thư..."
      @inputSearch="search = $event"
      @doneTyping="searchInput()"
    />
  </div>
</div>

<div class="overflow-x-auto">
  <div v-if="!mails.length" class="bg-white px-5 py-3 text-center no-data">
    Không có dữ liệu
  </div>

  <div v-else>
    <el-scrollbar class="height-table" v-loading="isLoading">
      <div
        v-for="mail in mails"
        :key="mail.id"
        class="
          border
          minWidthLg
          bg-white
          relative
          items-center
          hover:bg-gray-200
        "
      >
        <div class="z-10" @click="clickRow(mail.id)">
          <div class="flex py-2 px-4 items-start justify-between">
            <div class="flex items-start">
              <div class="mr-2 w-full">{{ mail.subject }}</div>
              <div class="min-w-max text-center mx-3">
                {{ mail.receiver + " [" }} {{ mail.statistic.pdf + " pdf," }} {{
                mail.statistic.xml + " xml," }} {{ mail.statistic.add + " mới,"
                }} {{ mail.statistic.update + " cập nhật," }} {{
                mail.statistic.fail + " lỗi]" }}
              </div>
            </div>
            <div class="flex items-center justify-end min-w-max ml-3">
              <div class="loading-invoice" v-if="mail.is_updating">
                <loading-invoice textContent="Hệ thống đang xử lý mail..." />
              </div>
              <div @mouseleave="isDetailClickable = true" @mouseover="isDetailClickable = false" class="z-50 flex items-center justify-end" v-else>
                <el-button @click="reReadMailBox(mail.id)" size="mini">
                  Đọc lại
                </el-button>
                <el-tooltip
                  class="item ml-2"
                  effect="dark"
                  content="Đã xử lý"
                  placement="top"
                >
                  <img
                    src="@/assets/icons/icon_check.svg"
                    alt=""
                    class="mr-1 w-4 h-4 fill-color"
                  />
                </el-tooltip>
              </div>
              <div
                class="
                  bg-gray-500
                  font-light
                  text-sm
                  px-3
                  rounded-full
                  text-white
                "
              >
                <span>
                  {{ formatDate(mail.created_at) + " " +
                  formatTime(mail.created_at) }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-scrollbar>
  </div>
</div>

<div>
  <paginate
    :currentPage="paginate.currentPage"
    :totalItem="paginate.totalItem"
    :totalPage="paginate.totalPage"
    :limit="paginate.limit"
    @modifiedCurrentPage="handleCurrentPageChange($event)"
    @update:limit="handleLimitChange"
  />
</div>
